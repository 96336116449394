<template>
  <div>
    <Head-section></Head-section>
    <div class="container">
      <!-- <video class="video-fluid"  autoplay loop controls muted>
        <source :src="video.playerurl" type="video/mp4">
      </video>-->
      <!-- <div class="embed-responsive embed-responsive-16by9" v-if="video!=null">
        <iframe class="embed-responsive-item" :src="video.playerurl" allowfullscreen></iframe>
      </div>-->

      <b-embed
        v-if="video!=null"
        type="iframe"
        aspect="16by9"
        :src="video.playerurl"
        allowfullscreen
      ></b-embed>

      <!-- <div class="embed-responsive embed-responsive-16by9">
  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
      </div>-->
      <p>{{$t("message.Related_Video_Recommendation")}}</p>

      <div class="scrolling-wrapper-flexbox">
        <div class="card wh30" v-for="(item) in items" :key="item" @click='toVdId(item.id)'>
          <b-img left thumbnail :src="item.thumbnails" alt="Bluedio Video"></b-img>
          <h5 class="mt-0 mb-1">{{item.name}}</h5>
          <p class="mb-0">{{item.videodate}}</p>
        </div>
      </div>
    </div>
    <Foot-section></Foot-section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import HeadSection from "./HeadCommSection.vue";
import HeadSection from "./HeadSection.vue";
import FootSection from "./FootSection.vue";
export default {
  name: "Video",
  components: { HeadSection, FootSection },
  data() {
    return {
      items: [],
      vid: 0,
      video: null
    };
  },
  computed: mapGetters(["lan_array"]),
  created() {
    let language = this.$route.params.language;
    if(this.$store.state.lan_array.indexOf(language) == -1){
      this.toHome();
    }
    this.getVd(this.vid);
  },

  methods: {
    ...mapActions({
      getVd(dispatch, vid) {
        dispatch("getVd", { vid }).then(data => {
          if (data.result) {
            this.video = data.video;
            this.items = data.items;
          }
        });
      }
    }),
    toVdId(id) {
      this.vid=id
      this.getVd(id);
    },
    toHome() {
      let nameHome = this.lan + "home";
      this.$router.replace({
        name: nameHome
      });
    },
  }
};
</script>

<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>
<template>
  <div style="background:#7777777; color:#7777777;">
    <Head-section></Head-section>

    <div class="alert alert-dark" role="alert">
  <H4>{{ $t("message.Game_title") }}</H4>
    </div>
    
    <div class="card mb-3">
    <div>
      <b-card-img-lazy href="#" :src="bggifurl1" class="rounded-0" v-if="isandroid"></b-card-img-lazy>
      <video-bg id="video1" :sources="[backvideourl1]" :img="backvideocoverurl1" webkit-playsinline="true" playsinline="true" v-else>
      </video-bg> 
      
     </div>
    </div>  
    <br>

<a href="/dreamtime"> 
<div class="div2" ref="scroll1">
  <b-card-img-lazy href="#" :src="storyimgurl" class="rounded-0" :style="{transform:'scale('+scale+')'}" v-if="ismob"></b-card-img-lazy>  
  <b-card-img-lazy href="#" :src="storyimgurl" class="rounded-0" v-else></b-card-img-lazy>  
</div>
</a>  

<br>

<b-card-img-lazy href="#" :src="bggifurl2" class="rounded-0" v-if="isandroid"></b-card-img-lazy>

<video-bg id="video2" :sources="[backvideourl2]" :img="backvideocoverurl2" webkit-playsinline="true" playsinline="true" v-else>
</video-bg>  


<a href="/business/investment"> 
  <div class="div2" ref="scroll2" v-if="ismob">
    <br>
  <b-card-img-lazy href="#" src="https://aimainland.com/img/dreamtime/intro_cn/pdf_0.jpg" class="rounded-0" :style="{transform:'scale('+scale2+')'}" v-if="iszh"></b-card-img-lazy>  
  <b-card-img-lazy href="#" src="https://aimainland.com/img/dreamtime/intro_en/pdf_0.jpg" class="rounded-0" :style="{transform:'scale('+scale2+')'}" v-else></b-card-img-lazy> 
  </div>
  <div class="div2" ref="scroll2" v-else>
    <br>
  <b-card-img-lazy href="#" src="https://aimainland.com/img/dreamtime/intro_cn/pdf_0.jpg" class="rounded-0" v-if="iszh"></b-card-img-lazy>  
  <b-card-img-lazy href="#" src="https://aimainland.com/img/dreamtime/intro_en/pdf_0.jpg" class="rounded-0" v-else></b-card-img-lazy> 
  </div>
</a>
    
      <div v-if="iszh">
        <div class="dt-mob" v-if="ismob">
          <a href="https://store.steampowered.com/app/1360700/DREAM_TIME/">
          <img :src="steamimgurl" alt="">
          </a> 
        </div>
        <div class="dt-logo" v-else>
          <a href="https://store.steampowered.com/app/1360700/DREAM_TIME/">
          <img :src="steamimgurl" alt="">
          </a> 
        </div>
      </div>
      <div v-else>
        <div class="dt-mob-top" v-if="ismob">
          <a href="#">
          <img :src="steamimgurl2" alt="">
          </a> 
        </div>
        <div class="dt-mob" v-if="ismob">
          <a href="https://store.steampowered.com/app/1360700/DREAM_TIME/">
          <img :src="steamimgurl" alt="">
          </a> 
        </div>
        <div class="dt-logo-top" v-if="!ismob">
          <a href="#">
          <img :src="steamimgurl2" alt="">
          </a> 
        </div>
        <div class="dt-logo" v-if="!ismob">
          <a href="https://store.steampowered.com/app/1360700/DREAM_TIME/">
          <img :src="steamimgurl" alt="">
          </a> 
        </div>
      </div>
    <br><br><br><br>
    <Foot-section></Foot-section>
    
  </div>
</template>

<script>
  import HeadSection from "./HeadSection.vue";
  import FootSection from "./FootSection.vue";
  import dreamtime from "../assets/dreamtime.png";
    // import webm from "../assets/mv2.mp4";
  import cover from "../assets/banner5.jpg";
  // import pdf_0 from "../assets/intro/pdf_0.jpg";
  import bg1_480 from "../assets/video/bg1_480.mp4";
  import bg2_480 from "../assets/video/bg2_480.mp4";
  import bg1_720 from "../assets/video/bg1_720.mp4";
  import bg2_720 from "../assets/video/bg2_720.mp4";
  import steam1 from "../assets/steam/steam.png";
  import steam02 from "../assets/steam/steam02.png";
  import steam03 from "../assets/steam/steam03.png";
  import story_cn from "../assets/story_cn.jpg";
  import story_en from "../assets/story_en.jpg";
  import bg1 from "../assets/bg1_720.jpg";
  import bg2 from "../assets/bg2_720.jpg";
  import bggif1 from "../assets/video/bg1.gif";
  import bggif2 from "../assets/video/bg2.gif";
  import {mapActions,mapGetters} from "vuex";
import Video from './Video.vue';

  export default {
    name: "Home4",
    components: {
      HeadSection,
      FootSection,
        Video
    },
    data() {
      return {
        scale:1.0,
        scale2:1.0,
        dreamtimeurl:dreamtime,
        // webmurl:webm,
        coverurl:cover,
        videourl:"",
        backvideourl1:"",
        backvideourl2:"",
        backvideocoverurl1:bg1,
        backvideocoverurl2:bg2,
        steamimgurl:"",
        steamimgurl2:"",
        storyimgurl:"",
        bggifurl1:bggif1,
        bggifurl2:bggif2,
        videoClick:false,
        // pdf_0_url:pdf_0,
        slide: 0,
        // sliding: null,
        navs: [],
        topPrds: [],
        adPrds: [],
        prds: [],
        spin_run: false,
        info: null,
         mainProps: {
          center: true,
          fluidGrow: true,
          blank: true,
          blankColor: '#bbb',
          class: 'my-1'
        },
 
      };
    },
    mounted() {
      this.steamimgurl2 = steam03;
      this.steamimgurl = steam02;
      this.storyimgurl = story_en;
      if(this.iszh){
        this.storyimgurl = story_cn;
        this.steamimgurl = steam1;
      }
      
        
      this.backvideourl1 = bg1_720;
      this.backvideourl2 = bg2_720;
      if(this.ismob){
        this.backvideourl1 = bg1_480;
        this.backvideourl2 = bg2_480;
      }
      if(this.ismob){
        window.addEventListener("scroll",this.handleScroll); 
      }
      
    },
    computed: {
      ...mapGetters(["lan","iszh","ismob","isandroid"]),
      

    },
    destroyed(){
      
      if(this.ismob){
        document.removeEventListener("scroll",this.handleScroll); 
      }
    },
    watch: {
      lan(oldlan, newlan) {
        
      }
    },
    methods: {
      ...mapActions({
        getPrds(dispatch) {
          this.spin_run = false;
          dispatch("getPrds", {}).then(data => {
            this.info = data;
            this.spin_run = data.result;
            if (data.result) {
              this.navs = data.navs;
              this.topPrds = data.star1;
              this.adPrds = data.star2;
              this.prds = data.star3;
            }
          });
        }
      }),
      toProDet(id) {
        let nameProduct = this.lan + 'Product';
        this.$router.push({
          name: nameProduct,
          params: {
            proid: id
          }
        });
      },
      toGame() {
        if(this.iszh){
          this.$router.push("/dreamtime");
        }else{
          this.$router.push("/game/dreamtime");
        }
      },
      toPostAll() {
        this.$router.push("/en/blog");
      },
      handleScroll() {
//获取滚动时的高度
        if(this.ismob){

        }
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // let oneHeight = this.$refs.scroll1.offsetHeight;
        // let twoHeight = this.$refs.scroll2.offsetHeight;

        if(this.ismob){
           if (scrollTop < 300 && scrollTop>5) {
              this.scale=1.1;
              this.scale2=1.0;
            }
            else if (scrollTop > 400) {
               this.scale=1.0;
              this.scale2=1.1;
            }else{
              this.scale=1.0;
              this.scale2=1.0;
            }
        

        }
       
        
      },
      videoChange(index){
         
      }
    }
  };
</script>
<style>
.card {
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.0rem;
}
.card mb3{
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.0rem;
}
.divider {
    height: 1px;
    margin-top: 40px;
    text-align:center;
    border-top: 1px solid #e2e2e2;
}
.divider span{
    position: relative;
    top: -20px;
    background: #fff;
    font-size: 28px;
    font-weight: bold;
    padding: 0 24px;
}

.yellow{
color:#FFCC66;
}

.btn-link {
    color: white;
    
}

.btns{
  position:absolute;
  bottom: 24px;
  margin:0 auto;
  right: 5%;
  text-align: center;
}


.VideoBg{
    width: 100%;
    min-height: 53vw;
    max-height: 54vw;
}

.VideoBg__content {
    color: white;
}

#bg_banner{
    position: relative;
    text-align:center;
    background:url("../assets/banner1.jpg") no-repeat; 
    width: 100%;
    background-size: 100%; 
    min-height: 53vw;
    }

.alert-dark {
    color: #1b1e21;
    background-color: #777777;
    border-color: #c6c8ca;
    text-align: center;
}

.alert {
    border: 0px solid transparent;
    border-radius: 0.0rem;
    margin-bottom: 0rem;
}
.card-body {
    padding: 0.0rem;
}

.card-img-overlay {
    position: absolute;
    text-align: center;
}

.card-img {
    border-radius: 0;
}

.wh30 {
    margin-right: 0.5rem;
    width: 24rem;
    height: 14rem;
}

.mb-3, .my-3 {
    margin-bottom: 0rem !important;
}

.dt-logo-top img{
 position:fixed;
 width: 20%;
 right: 2px;
 bottom: 12vw;
 z-index: 9996;
}

.dt-mob-top img{
 position:fixed;
 width: 33%;
 right: 2px;
 bottom: 31vw;
 z-index: 9997;
}

.dt-logo img{
 position:fixed;
 width: 20%;
 right: 2px;
 bottom: 3vw;
 z-index: 9998;
}

.dt-mob img{
 position:fixed;
 width: 33%;
 right: 2px;
 bottom: 18vw;
 z-index: 9999;
}

.div2{
  overflow: hidden;
}

.div2 img{
  cursor: pointer;
  transition: all 0.6s;
}

.div2 img:hover{
  transform: scale(1.1);
}
</style>